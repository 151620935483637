<template>
  <div v-if="props.id">
    <vl-popover>
      <template v-slot:label>
        <vl-button
          mod-icon
          mod-secondary
          mod-naked
          icon="nav-show-more-vertical"
          class="deliveryprocessdefinition-action-button"
        >
        </vl-button>
      </template>
      <div class="vl-popover-content">
        <vl-link-list>
          <vl-link mod-block @click="showChangeStatusModal">{{
            $t('deliveryprocessdefinition.actions.changeStatus.label')
          }}</vl-link>
          <vl-link mod-block @click="showDuplicationModal">{{
            $t('deliveryprocessdefinition.actions.duplicate.label')
          }}</vl-link>
          <vl-link v-if="isDraft" mod-block @click="showDryRunModal">{{
            $t('deliveryprocessdefinition.actions.dryrun.label')
          }}</vl-link>
          <vl-link v-if="isDraft" mod-block @click="showCascadeDeleteModal">{{
            $t('deliveryprocessdefinition.actions.cascadeDelete.label')
          }}</vl-link>
        </vl-link-list>
      </div>
    </vl-popover>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import DefinitionStatusForm from '../definition-status-form/DefinitionStatusForm.vue';
import DefinitionDuplicationForm from '../definition-duplication-form/DefinitionDuplicationForm.vue';
import DefinitionDryRunForm from '../definition-dryrun-form/DefinitionDryRunForm.vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { computed } from 'vue';
import { useDeliveryProcessDefinitionListStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-list.store';
import DefinitionCascadeDeleteForm from '../definition-cascade-delete-form/DefinitionCascadeDeleteForm.vue';

const listStore = useDeliveryProcessDefinitionListStore();
const modalStore = useModalStore();
const { t } = useI18n();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const definition = computed(() => listStore.getDefinition(props.id));

const isDraft = computed(() => definition.value?.statusId === 0);

const showChangeStatusModal = () => {
  const modalContent = {
    title: t('deliveryprocessdefinition.actions.changeStatus.description'),
    component: DefinitionStatusForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};

const showDuplicationModal = () => {
  const modalContent = {
    title: t('deliveryprocessdefinition.actions.duplicate.description'),
    component: DefinitionDuplicationForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};

const showDryRunModal = () => {
  const modalContent = {
    title: t('deliveryprocessdefinition.actions.dryrun.description'),
    component: DefinitionDryRunForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};

const showCascadeDeleteModal = () => {
  const modalContent = {
    closable: false,
    title: t('deliveryprocessdefinition.actions.cascadeDelete.description'),
    component: DefinitionCascadeDeleteForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};
</script>
<style lang="scss" scoped>
.deliveryprocessdefinition-action-button {
  min-height: 1.2em;
}
</style>
