<template>
  <form class="vl-form" @submit.prevent="onSubmit()">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-properties-list v-if="data.definition">
          <vl-properties-label>{{ $t('deliveryprocessdefinition.detail.id') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.id }}</vl-properties-data>
          <vl-properties-label>{{ $t('deliveryprocessdefinition.detail.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.label }}</vl-properties-data>
          <vl-properties-label>{{ $t('deliveryprocessdefinition.detail.organisation') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.organisation }} ({{ data.definition.ovoNumber }})</vl-properties-data>
        </vl-properties-list>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField
            name="includeProductProcessDefinitions"
            :label="$t('deliveryprocessdefinition.actions.dryrun.includeProductProcessDefinitions')"
          />
        </vl-form-column>
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{
            $t('deliveryprocessdefinition.actions.dryrun.label')
          }}</vl-button>
          <vl-button :mod-disabled="data.isSaving" mod-icon-before mod-secondary tag-name="a" @click.prevent="cancel">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { useDeliveryProcessDefinitionDetailStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-detail.store';
import { useDeliveryRunStore } from '@/modules/configuration/store/delivery-run/delivery-run.store';
import { reactive } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useForm, Field } from 'vee-validate';
import { boolean, object } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';

const detailStore = useDeliveryProcessDefinitionDetailStore();
const runStore = useDeliveryRunStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const validationSchema = object({
  includeProductProcessDefinitions: boolean(),
});

const { handleSubmit, resetForm } = useForm({ validationSchema });

const data = reactive({
  definition: null,
  isSaving: false,
});

onMounted(async () => {
  resetForm({
    values: {
      includeProductProcessDefinitions: true,
    },
  });

  const deliveryProcessDefinition = await detailStore.get(props.id, true);
  if (deliveryProcessDefinition) {
    data.definition = deliveryProcessDefinition;
  }
});

const onSuccess = async (values) => {
  await startdryrun(values.includeProductProcessDefinitions);

  closeModal();
};

const startdryrun = async (includeProductProcessDefinitions: boolean): Promise<void> => {
  try {
    data.isSaving = true;
    await runStore.startdryrun(props.id, includeProductProcessDefinitions);
  } catch (e) {
    console.error(e);
  } finally {
    data.isSaving = false;
  }
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>
