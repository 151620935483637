<template>
  <form class="vl-form" @submit.prevent="onSubmit()">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-properties-list v-if="data.definition">
          <vl-properties-label>{{ $t('productprocessdefinition.detail.id') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.id }}</vl-properties-data>
          <vl-properties-label>{{ $t('productprocessdefinition.detail.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.label }}</vl-properties-data>
        </vl-properties-list>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField
            name="deleteEntityModels"
            :label="$t('productprocessdefinition.actions.cascadeDelete.deleteEntityModels')"
          />
        </vl-form-column>
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving || !data.canDelete" :mod-loading="data.isSaving">{{
            $t('general.delete')
          }}</vl-button>
          <vl-link
            :mod-disabled="data.isSaving"
            :mod-loading="data.isSaving"
            mod-button
            mod-secondary
            target="_blank"
            :to="`/configuration/productprocessdefinition/${props.id}/dependencies`"
          >
            {{ $t('productprocessdefinition.detail.dependencies') }}
          </vl-link>
          <vl-button :mod-disabled="data.isSaving" mod-secondary @click.prevent="cancel">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { useProductProcessDefinitionListStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-list.store';
import { reactive } from 'vue';
import { useForm } from 'vee-validate';
import { boolean, object } from 'yup';
import { useModalStore } from '@/modules/core/store/modal.store';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';

const detailStore = useProductProcessDefinitionDetailStore();
const listStore = useProductProcessDefinitionListStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const validationSchema = object({
  deleteEntityModels: boolean(),
});

const { handleSubmit, resetForm, values } = useForm({ validationSchema });

const data = reactive({
  definition: null,
  isLoading: false,
  isSaving: false,
  canDelete: false,
});

onMounted(async () => {
  resetForm({
    values: {
      deleteEntityModels: true,
    },
  });

  data.isLoading = true;
  const definition = await detailStore.get(props.id);
  if (definition) {
    data.definition = definition;
    data.canDelete = await detailStore.validateCascadeDelete(props.id);
  }
  data.isLoading = false;
});

const onSuccess = async () => {
  const isSuccess = await cascadeDelete();
  if (isSuccess) {
    listStore.getDefinitions();
  }

  closeModal();
};

const cascadeDelete = async (): Promise<boolean> => {
  if (data.isSaving) {
    return false;
  }

  let isSuccess = false;
  try {
    data.isSaving = true;
    isSuccess = await detailStore.cascadeDelete(props.id, {
      deleteEntityModels: values.deleteEntityModels,
    });
  } catch (e) {
    console.error(e);
  } finally {
    data.isSaving = false;
  }
  return isSuccess;
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>
