<template>
  <div v-if="props.id">
    <vl-popover>
      <template v-slot:label>
        <vl-button mod-icon mod-secondary mod-naked icon="nav-show-more-vertical" class="entitymodel-action-button">
        </vl-button>
      </template>
      <div class="vl-popover-content">
        <vl-link-list>
          <vl-link mod-block @click="showChangeStatusModal">{{ $t('entitymodel.actions.changeStatus.label') }}</vl-link>
          <vl-link mod-block @click="showDuplicationModal">{{ $t('entitymodel.actions.duplicate.label') }}</vl-link>
          <vl-link v-if="isDraft" mod-block @click="showCascadeDeleteModal">{{
            $t('entitymodel.actions.cascadeDelete.label')
          }}</vl-link>
        </vl-link-list>
      </div>
    </vl-popover>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useI18n } from 'vue-i18n';
import EntityModelStatusForm from '../entity-model-status-form/EntityModelStatusForm.vue';
import EntityModelDuplicationForm from '../entity-model-duplication-form/EntityModelDuplicationForm.vue';
import EntityModelCascadeDeleteForm from '../entity-model-cascade-delete-form/EntityModelCascadeDeleteForm.vue';
import { useEntityModelListStore } from '@/modules/configuration/store/entity-model/entity-model-list.store';

const listStore = useEntityModelListStore();
const modalStore = useModalStore();

const { t } = useI18n();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const entityModel = computed(() => listStore.getEntityModel(props.id));

const isDraft = computed(() => entityModel.value?.statusId === 0);

const showChangeStatusModal = () => {
  const modalContent = {
    title: t('entitymodel.actions.changeStatus.description'),
    component: EntityModelStatusForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};

const showDuplicationModal = () => {
  const modalContent = {
    title: t('entitymodel.actions.duplicate.description'),
    component: EntityModelDuplicationForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};

const showCascadeDeleteModal = () => {
  const modalContent = {
    closable: false,
    title: t('entitymodel.actions.cascadeDelete.description'),
    component: EntityModelCascadeDeleteForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};
</script>
<style lang="scss" scoped>
.entitymodel-action-button {
  min-height: 1.2em;
}
</style>
