<template>
  <vl-layout>
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1">
          {{ $t('entitymodel.dependencies.title') }}
        </vl-title>
      </vl-column>
    </vl-grid>
    <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <EntityModelRead />
      </vl-column>
    </vl-grid>
    <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <EntityModelDependencies :id="id" />
      </vl-column>
    </vl-grid>
  </vl-layout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useEntityModelDetailStore } from '../../store/entity-model/entity-model-detail.store';
import { EntityModelDetailDto } from '@/api/portal-api/clients';
import EntityModelRead from './EntityModelRead.vue';
import EntityModelDependencies from './subcomponents/entity-model-dependencies/EntityModelDependencies.vue';

const store = useEntityModelDetailStore();
const { t } = useI18n();

const route = useRoute();

const detail = computed((): EntityModelDetailDto => {
  return store.detail;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  store.get(id.value);
});
</script>
