<template>
  <template v-if="dependenciesReport && !loading">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-title tag-name="h3">
        {{ $t('deliveryprocessdefinition.dependencies.productProcessDefinitions.title') }}
      </vl-title>
      <vl-column
        v-for="productprocessdefinition in dependenciesReport.productProcessDefinitions"
        :key="productprocessdefinition.id"
      >
        <router-link :to="`/configuration/productprocessdefinition/${productprocessdefinition.id}`" target="blank">
          {{ `[${productprocessdefinition.id}] ${productprocessdefinition.label}` }}
        </router-link>
      </vl-column>
      <vl-column v-if="dependenciesReport.productProcessDefinitions.length === 0">
        <vl-text>{{ $t('deliveryprocessdefinition.dependencies.productProcessDefinitions.noResults') }}</vl-text>
      </vl-column>
    </vl-grid>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-title tag-name="h3"> {{ $t('deliveryprocessdefinition.dependencies.processTriggers.title') }} </vl-title>
      <vl-column v-for="processTrigger in dependenciesReport.processTriggers" :key="processTrigger.id">
        <router-link :to="`/configuration/processtrigger/${processTrigger.id}`" target="blank">
          {{ `[${processTrigger.id}] ${processTrigger.description}` }}
        </router-link>
      </vl-column>
      <vl-column v-if="dependenciesReport.processTriggers.length === 0">
        <vl-text>{{ $t('deliveryprocessdefinition.dependencies.processTriggers.noResults') }}</vl-text>
      </vl-column>
    </vl-grid>
  </template>
  <template v-if="loading">
    <vl-region>
      <vl-loader :message="$t('deliveryprocessdefinition.dependencies.loading')" />
    </vl-region>
  </template>
</template>
<script lang="ts" setup>
import { DeliveryProcessDefinitionDependenciesReportDto } from '@/api/portal-api/clients';
import { useDeliveryProcessDefinitionDetailStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-detail.store';
import { computed, onMounted, reactive } from 'vue';

const store = useDeliveryProcessDefinitionDetailStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const data = reactive({
  isLoading: false,
});

const loading = computed(() => {
  return data.isLoading;
});

const dependenciesReport = computed((): DeliveryProcessDefinitionDependenciesReportDto => {
  return store.dependenciesReport;
});

onMounted(async () => {
  data.isLoading = true;
  await store.getDependenciesReport(props.id);
  data.isLoading = false;
});
</script>
<style lang="scss" scoped></style>
