<template>
  <NotFound v-if="notFound" />
  <vl-region v-if="loading">
    <div v-vl-align:center>
      <vl-loader :message="$t('general.pageLoading')" />
    </div>
  </vl-region>
  <vl-region v-if="delivery && !loading">
    <vl-layout>
      <vl-grid>
        <vl-column width="9" width-xs="12">
          <vl-alert
            v-if="alert.visible"
            :icon="alert.icon"
            :mod-error="alert.isError"
            :mod-success="alert.isSuccess"
            closable
            :close-text="$t('delivery.detail.alert.closeText')"
            :title="alert.title"
            :content="alert.content"
            @close="closeAlert"
          />
        </vl-column>
        <vl-column width="9" width-xs="12">
          <vl-title tag-name="h1">{{ $t('delivery.detail.title') }}</vl-title>
        </vl-column>
        <vl-column width="3" width-xs="12">
          <vl-button v-if="delivery.canAbort" type="button" mod-secondary @click="cancelDelivery">{{
            $t('delivery.detail.cancelDelivery.label')
          }}</vl-button>
        </vl-column>
        <vl-column>
          <vl-properties>
            <!--<vl-properties-title>Levering</vl-properties-title>-->
            <vl-properties-column>
              <vl-properties-list>
                <vl-properties-label>{{ $t('delivery.create.definition.label') }}</vl-properties-label>
                <vl-properties-data>{{ delivery.definition && delivery.definition.label }}</vl-properties-data>
                <vl-properties-label>{{ $t('delivery.detail.status.label') }}</vl-properties-label>
                <vl-properties-data>{{ delivery.status }}</vl-properties-data>
                <vl-properties-label v-if="delivery.statusId == 2">{{
                  $t('product.detail.reason.label')
                }}</vl-properties-label>
                <vl-properties-data v-if="delivery.statusId == 2">{{ delivery.failReason }}</vl-properties-data>
                <vl-properties-label>{{ $t('delivery.detail.organisation.label') }}</vl-properties-label>
                <vl-properties-data>{{ delivery.organisation }}</vl-properties-data>
                <vl-properties-label>{{ $t('delivery.detail.createdBy.label') }}</vl-properties-label>
                <vl-properties-data>{{ delivery.createdBy }}</vl-properties-data>
                <vl-properties-label>{{ $t('delivery.detail.createdOn.label') }}</vl-properties-label>
                <vl-properties-data>{{ dateTimeFilter(delivery.createdOn) }}</vl-properties-data>
                <vl-properties-label>{{ $t('delivery.detail.isDryRun.label') }}</vl-properties-label>
                <vl-properties-data>{{ delivery.isDryRun ? $t('general.yes') : $t('general.no')}}</vl-properties-data>
                <!-- <vl-properties-label>{{ $t('delivery.detail.product.label') }}</vl-properties-label>
                <vl-properties-data></vl-properties-data> -->
              </vl-properties-list>
            </vl-properties-column>
            <vl-properties-column>
              <vl-properties-list>
                <vl-properties-label>{{ $t('delivery.detail.dataset.label') }}</vl-properties-label>
                <vl-properties-data>
                  <vl-button
                    v-if="delivery.fileName"
                    type="button"
                    :mod-loading="loadingDownload"
                    mod-naked-action
                    @click="download"
                    >{{ $t('delivery.detail.dataset.action.label') }}</vl-button
                  >
                </vl-properties-data>
                <vl-properties-label>{{ $t('delivery.detail.feedback.label') }}</vl-properties-label>
                <vl-properties-data>
                  <a
                    v-if="delivery.qualityReport"
                    :href="delivery.qualityReport"
                    class="vl-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <vl-button
                      v-if="delivery.qualityReport"
                      type="button"
                      mod-naked-action
                      :mod-loading="loadingCancelDelivery"
                      >{{ $t('delivery.detail.feedback.action.label') }}
                    </vl-button>
                  </a>
                </vl-properties-data>
              </vl-properties-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
        <vl-column>
          <vl-properties>
            <vl-properties-column v-if="delivery.metadata && delivery.metadata.length" mod-full>
              <vl-properties-title>{{ $t('delivery.detail.metadata.title') }}</vl-properties-title>
              <vl-accordion-list v-if="delivery.metadata && delivery.metadata.length">
                <vl-accordion-list-item v-for="(metadata, i) in delivery.metadata" :id="i" :key="i">
                  <vl-accordion :id="i.toString()" :title="metadata.productSpecificationLabel" :open="true">
                    <vl-properties-list>
                      <vl-properties-label>{{ $t('delivery.detail.metadata.versionDate.label') }}</vl-properties-label>
                      <vl-properties-data>{{ dateFilter(delivery.metadata[i].versionDate) }}</vl-properties-data>
                      <vl-properties-label>{{ $t('delivery.detail.metadata.temporalLimitation.label') }}</vl-properties-label>
                      <vl-properties-data
                        >{{ dateFilter(delivery.metadata[i].temporalLimitationFrom) }} -
                        {{
                          dateFilter(delivery.metadata[i].temporalLimitationTo, $t('delivery.detail.noEndDate.label'))
                        }}</vl-properties-data
                      >
                      <vl-properties-label>{{ $t('delivery.detail.metadata.versionDescription.label') }}</vl-properties-label>
                      <vl-properties-data>{{ delivery.metadata[i].versionDescription }}</vl-properties-data>
                    </vl-properties-list>
                    </vl-accordion>
                </vl-accordion-list-item>
              </vl-accordion-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import { DeliveryDetailService, DeliveryDetailServiceInstance } from './DeliveryDetail.service';
import { dateFilter, dateTimeFilter } from '@/filters';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import NotFound from '@/core/security/NotFound.vue';

export default defineComponent({
  components: {
    NotFound,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const deliveryService: DeliveryDetailService = DeliveryDetailServiceInstance;

    // lifecycle hooks
    onMounted(async () => {
      await deliveryService.init(parseInt(route.params.id.toString(), 10));
      if (route.query.created === 'true') {
        alertSuccess(
          t('delivery.detail.created.alertSucces.content').toString(),
          t('delivery.detail.created.alertSucces.title').toString(),
        );
      }
    });

    onUnmounted(() => {
      deliveryService.reset();
    });

    // reactive data
    const data = reactive({
      alert: {
        visible: false,
        isError: false,
        isSuccess: false,
        title: '',
        content: '',
        icon: '',
      },
      loadingDownload: false,
      loadingCancelDelivery: false,
    });

    // computed values
    const delivery = computed(() => deliveryService.state.delivery);
    const notFound = computed(() => deliveryService.state.notFound);
    const loading = computed(() => deliveryService.state.loading);

    // methods
    const download = async () => {
      data.loadingDownload = true;
      try {
        await deliveryService.download();
      } catch (error) {
        alertError(
          t('delivery.detail.dataset.errorAlert.content').toString(),
          t('delivery.detail.dataset.errorAlert.title').toString(),
        );
      } finally {
        data.loadingDownload = false;
      }
    };

    const cancelDelivery = async () => {
      data.loadingCancelDelivery = true;
      try {
        await deliveryService.cancelDelivery();
        alertSuccess(
          t('delivery.detail.cancelDelivery.successAlert.content').toString(),
          t('delivery.detail.cancelDelivery.successAlert.title').toString(),
        );
        router.push({ name: 'delivery-list' });
      } catch (error) {
        alertError(
          t('delivery.detail.cancelDelivery.errorAlert.content').toString(),
          t('delivery.detail.cancelDelivery.errorAlert.title').toString(),
        );
      } finally {
        data.loadingCancelDelivery = false;
      }
    };

    const alertError = (message: string, title: string) => {
      data.alert = {
        visible: true,
        isError: true,
        isSuccess: false,
        icon: 'alert-circle',
        title: title,
        content: message,
      };
    };

    const alertSuccess = (message: string, title: string) => {
      data.alert = {
        visible: true,
        isError: false,
        isSuccess: true,
        icon: 'check',
        title: title,
        content: message,
      };
    };

    const closeAlert = () => {
      data.alert = {
        visible: false,
        isError: false,
        isSuccess: false,
        icon: '',
        title: '',
        content: '',
      };
    };

    return {
      ...toRefs(data),
      dateTimeFilter,
      dateFilter,
      download,
      cancelDelivery,
      closeAlert,
      delivery,
      notFound,
      loading,
    };
  },
});
</script>
