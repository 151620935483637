<template>
  <vl-popover
    v-if="filteredItems && filteredItems.length"
    ref="popover"
    :label-close-aria="labelCloseAria"
    :label="popoverLabel"
    icon="play"
    mod-disabled
  >
    <div class="vl-popover-content">
      <vl-link-list>
        <template v-for="item in filteredItems" :key="item">
          <vl-link mod-block @click="item.click">{{ item.label }}</vl-link>
        </template>
      </vl-link-list>
    </div>
  </vl-popover>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';

export type PbsActionButtonItem = {
  label: string;
  disabled?: boolean;
  click: (e: any) => void;
};

const props = defineProps({
  modDisabled: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: true,
    default: 'Meer acties',
  },
  labelCloseAria: {
    type: String,
    required: false,
    default: 'Sluiten',
  },
  items: {
    type: Array as PropType<PbsActionButtonItem[]>,
    required: false,
    default: () => [],
  },
});

const filteredItems = computed(() => {
  return props.items?.filter((x) => !x.disabled);
});

const popoverLabel = computed(() => {
  const label = {
    text: props.label,
  };
  return label;
});
</script>

<style lang="scss" scoped>
.action-button {
  min-height: 1.2em;
}

:deep(.vl-popover__toggle) {
  background-color: var(--vl-theme-action-color, #05c) !important;
  padding: 7px 15px;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;

  &:active,
  &:hover {
    background-color: var(--vl-theme-action-active-color, #004099) !important;
  }
}
</style>
