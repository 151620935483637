<template>
  <vl-layout>
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1">
          {{ $t('deliveryprocessdefinition.dependencies.title') }}
        </vl-title>
      </vl-column>
    </vl-grid>
    <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <DeliveryProcessDefinitionRead />
      </vl-column>
    </vl-grid>
    <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <DefinitionDependencies :id="id" />
      </vl-column>
    </vl-grid>
  </vl-layout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useDeliveryProcessDefinitionDetailStore } from '../../store/delivery-process-definition/delivery-process-definition-detail.store';
import { DeliveryProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import DeliveryProcessDefinitionRead from './DeliveryProcessDefinitionRead.vue';
import DefinitionDependencies from './subcomponents/definition-dependencies/DefinitionDependencies.vue';

const store = useDeliveryProcessDefinitionDetailStore();
const { t } = useI18n();

const route = useRoute();

const detail = computed((): DeliveryProcessDefinitionDetailDto => {
  return store.detail;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  store.get(id.value);
});
</script>
