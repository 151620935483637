<template>
  <vl-layout>
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1">
          {{ $t('productprocessdefinition.dependencies.title') }}
        </vl-title>
      </vl-column>
    </vl-grid>
    <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <ProductProcessDefinitionRead />
      </vl-column>
    </vl-grid>
    <vl-grid v-if="detail" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <DefinitionDependencies :id="id" />
      </vl-column>
    </vl-grid>
  </vl-layout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useProductProcessDefinitionDetailStore } from '../../store/product-process-definition/product-process-definition-detail.store';
import { ProductProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import ProductProcessDefinitionRead from './ProductProcessDefinitionRead.vue';
import DefinitionDependencies from './subcomponents/definition-dependencies/DefinitionDependencies.vue';

const store = useProductProcessDefinitionDetailStore();
const { t } = useI18n();

const route = useRoute();

const detail = computed((): ProductProcessDefinitionDetailDto => {
  return store.detail;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  store.get(id.value);
});
</script>
