<template>
  <template v-if="dependenciesReport && !loading">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-title tag-name="h3"> {{ $t('productprocessdefinition.dependencies.processTriggers.title') }} </vl-title>
      <vl-column v-for="processTrigger in dependenciesReport.processTriggers" :key="processTrigger.id">
        <router-link :to="`/configuration/processtrigger/${processTrigger.id}`" target="blank">
          {{ `[${processTrigger.id}] ${processTrigger.description}` }}
        </router-link>
      </vl-column>
      <vl-column v-if="dependenciesReport.processTriggers.length === 0">
        <vl-text>{{ $t('productprocessdefinition.dependencies.processTriggers.noResults') }}</vl-text>
      </vl-column>
    </vl-grid>
  </template>
  <template v-if="loading">
    <vl-region>
      <vl-loader :message="$t('productprocessdefinition.dependencies.loading')" />
    </vl-region>
  </template>
</template>
<script lang="ts" setup>
import { ProductProcessDefinitionDependenciesReportDto } from '@/api/portal-api/clients';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { computed, onMounted, reactive } from 'vue';

const store = useProductProcessDefinitionDetailStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const data = reactive({
  isLoading: false,
});

const loading = computed(() => {
  return data.isLoading;
});

const dependenciesReport = computed((): ProductProcessDefinitionDependenciesReportDto => {
  return store.dependenciesReport;
});

onMounted(async () => {
  data.isLoading = true;
  await store.getDependenciesReport(props.id);
  data.isLoading = false;
});
</script>
<style lang="scss" scoped></style>
