<template>
  <form class="vl-form" @submit.prevent="onSubmit">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField
            name="includeProductMetadata"
            :label="$t('productprocessdefinition.actions.export.includeProductMetadata')"
          />
        </vl-form-column>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{
            $t('productprocessdefinition.actions.export.label')
          }}</vl-button>
          <vl-button
            v-focus
            :mod-disabled="data.isSaving"
            mod-icon-before
            mod-secondary
            tag-name="a"
            @click.prevent="cancel"
          >
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import 'vue-i18n';
import { useForm } from 'vee-validate';
import { onMounted, reactive } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { boolean, object } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';

const store = useProductProcessDefinitionDetailStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const data = reactive({
  isSaving: false,
});

const validationSchema = object({
  includeMetadata: boolean(),
});

const { handleSubmit, values, resetForm } = useForm({
  validationSchema,
});

onMounted(async () => {
  resetForm({
    values: {
      includeProductMetadata: false,
    },
  });
});

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onSuccess = async () => {
  const { includeProductMetadata } = values;

  await store.export(props.id, {
    includeProductMetadata,
  });

  closeModal();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>
