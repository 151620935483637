<template>
  <vl-region>
    <vl-layout>
      <vl-grid>
        <vl-column>
          <vl-title tag-name="h1">{{ $t('delivery.create.title') }}</vl-title>
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <vl-alert
            v-if="data.alert.visible"
            v-scroll="data.alert.scrollTo"
            class="vl-u-spacer--small"
            :icon="data.alert.icon"
            :mod-error="data.alert.isError"
            :mod-success="data.alert.isSuccess"
            closable
            :close-text="$t('delivery.detail.alert.closeText')"
            :title="data.alert.title"
            :content="data.alert.content"
            @close="closeAlert"
          />
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <form @submit.prevent="onSubmit">
            <vl-grid mod-stacked>
              <vl-column>
                <vl-grid>
                  <vl-form-column>
                    <vl-title tag-name="h2">
                      {{ $t('delivery.create.fileDetails.title') }}
                    </vl-title>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.small>
                    <PbsSelectField
                      name="definition"
                      :label="t('delivery.create.definition.label')"
                      :placeholder="$t('delivery.create.definition.label')"
                      :options="definitions"
                      :custom-label="getDefinitionLabel"
                    />
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <Field v-slot="{ handleChange, value }" name="isFirstTime">
                      <vl-checkbox
                        id="isFirstTime"
                        name="isFirstTime"
                        :value="value"
                        @input="(evt) => onCheckboxChecked(evt, handleChange)"
                        >{{ $t('delivery.create.isFirstTime.description') }}&nbsp;
                        <strong> {{ $t('delivery.create.isFirstTime.label') }} </strong></vl-checkbox
                      >
                    </Field>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <vl-grid>
                  <vl-form-column>
                    <vl-title tag-name="h2">{{ $t('delivery.create.fileUpload.label') }}</vl-title>
                  </vl-form-column>
                  <vl-form-column v-show="!loading" v-vl-spacer:bottom.medium>
                    <vl-form-message-annotation v-vl-spacer:bottom.small>
                      <vl-typography>
                        <p>
                          {{ $t('delivery.create.fileUpload.help.title') }}
                        </p>

                        <ul>
                          <li>{{ $t('delivery.create.fileUpload.help.zipfile') }}</li>
                          <li>{{ $t('delivery.create.fileUpload.help.maxlength') }}</li>
                          <li>{{ $t('delivery.create.fileUpload.help.filetypes') }}</li>
                        </ul>
                      </vl-typography>
                    </vl-form-message-annotation>
                    <Field v-slot="{ handleChange, value, errors }" name="file">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-upload
                        id="data-delivery-upload"
                        name="data-delivery-upload"
                        :upload-label="$t('delivery.create.fileUpload.label')"
                        allowed-file-types=".zip,.7z"
                        :max-filesize="750"
                        upload-drag-text="Sleep de bijlage naar hier om ze toe te voegen"
                        upload-response-error="Er is iets misgelopen tijdens de upload"
                        upload-invalid-file-type="Ongeldig bestandstype"
                        upload-max-files-exceeded="Het opgeladen bestand is te groot"
                        upload-files-hidden-close-label=""
                        upload-max-file-size="Het bestand van maximaal "
                        url="/api/v1/deliveryrun"
                        :mod-error="!!errors.length"
                        :max-files="1"
                        :options="options"
                        :value="value"
                        @upload-file-added="handleChange"
                        @upload-removed-file="removeFile"
                      />
                    </Field>
                  </vl-form-column>
                  <vl-form-column v-if="loading" v-vl-spacer:bottom.medium>
                    <div class="pbs-upload-progress">
                      <p class="pbs-upload-progress__file">{{ delivery.file.name }}</p>
                      <p class="pbs-upload-progress__label">
                        {{ progress }}%
                        <span class="vl-u-visually-hidden">{{ $t('create.delivery.uploaded') }}</span>
                      </p>
                      <vl-progress
                        mod-large
                        :value="progress"
                        max="100"
                        aria-valuenow="23"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :aria-valuetext="$t('create.delivery.uploaded')"
                      />
                    </div>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column v-if="metadata && metadata.length">
                <vl-grid>
                  <vl-form-column v-vl-spacer:bottom.small>
                    <vl-title tag-name="h2">{{ $t('delivery.create.deliveryDetails.title') }}</vl-title>
                    <vl-annotation>{{ $t('delivery.create.deliveryDetails.help') }}</vl-annotation>
                  </vl-form-column>
                  <vl-accordion-list v-if="metadata && metadata.length">
                    <DeliveryMetadataCreate />
                  </vl-accordion-list>
                </vl-grid>
              </vl-column>
              <vl-column>
                <div class="vl-u-spacer"></div>
                <vl-action-group>
                  <vl-button type="submit" :mod-loading="loading">{{ $t('delivery.create.confirm.label') }}</vl-button>
                  <vl-button type="button" mod-secondary @click="cancel">{{
                    $t('delivery.create.cancel.label')
                  }}</vl-button>
                </vl-action-group>
              </vl-column>
            </vl-grid>
          </form>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts" setup>
import { reactive, onMounted, computed, watch, onUnmounted } from 'vue';
import { DeliveryCreateServiceInstance, CreateDelivery } from './DeliveryCreate.service';
import { useRouter } from 'vue-router';
import { DropzoneFile, DropzoneOptions } from 'dropzone';
import type Dropzone from 'dropzone';
import { Form, Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { parseFormattedDateString, yupTransformDate } from '@/helpers';
import { useI18n } from 'vue-i18n';
import DeliveryMetadataCreate from './subcomponents/DeliveryMetadataCreate.vue';
import { Metadata, ProductSpecification } from '@/api/clients';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import { getDefinitionLabel } from './common/';

const router = useRouter();

const data = reactive({
  deliveryService: DeliveryCreateServiceInstance,
  alert: {
    visible: false,
    isError: false,
    isSuccess: false,
    scrollTo: false,
    title: '',
    content: '',
    icon: '',
  },
});

const { t } = useI18n();

const options: DropzoneOptions = {
  init: function (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const dropzone: Dropzone = this;
    // Hack to fix 'reuploading' of a file. Uploading a new file replaces the other one.
    dropzone.on('maxfilesexceeded', function (file: DropzoneFile) {
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    });
  },
};

const translations = {
  definition: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.definition.label'),
    }),
    oneOf: t('validations.messages.oneOf', {
      _field_: t('delivery.create.definition.label'),
    }),
  },
  versionDate: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.versionDate.label'),
    }),
  },
  temporalLimitationFrom: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.temporalLimitation.from.label'),
    }),
  },
  versionDescription: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.description.label'),
    }),
  },
  file: {
    required: t('validations.messages.required', {
      _field_: t('delivery.create.fileUpload.label'),
    }),
    fileType: t('validations.messages.invalidFileType'),
    fileSize: t('validations.messages.invalidFileSize'),
  },
  form: {
    uploadFailed: t('deliver.create.uploadfailed'),
  },
};

const validationSchema = yup.object({
  definition: yup.object().required(translations.definition.required),
  isFirstTime: yup.boolean(),
  file: yup
    .mixed()
    .required(translations.file.required)
    .test('is-valid-type', translations.file.fileType, (value) => {
      return (
        value && ((value as File).type === 'application/x-zip-compressed' || (value as File).type === 'application/zip')
      );
    })
    .test('is-valid-size', translations.file.fileSize, (value) => value && (value as File).size <= 750 * 1000 * 1000),
  metadata: yup.array().of(
    yup
      .object()
      .shape({
        versionDate: yup.date().transform(yupTransformDate).required(translations.versionDate.required),
        temporalLimitationFrom: yup
          .date()
          .transform(yupTransformDate)
          .required(translations.temporalLimitationFrom.required),
        temporalLimitationTo: yup.date().nullable().transform(yupTransformDate),
        versionDescription: yup
          .string()
          .required(translations.versionDescription.required)
          .max(
            100,
            t('validations.messages.maxChar', { _field_: t('delivery.create.description.label'), length: 100 }),
          ),
        specification: yup.object().shape({
          id: yup.number().required(),
          label: yup.string().required(),
        }),
      })
      .strict()
      .required(),
  ),
});

const { resetForm, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const delivery = computed(() => data.deliveryService.delivery);
const definitions = computed(() => data.deliveryService.definitions?.filter((d) => [0, 1].includes(d.statusId)));
const loading = computed(() => data.deliveryService.loading);
const progress = computed(() => data.deliveryService.uploadProgress);
const specifications = computed(() => data.deliveryService.productspecifications);
const selectedDeliveryDefinition = computed(() => values.definition);
const metadata = computed(() => values.metadata);

watch(selectedDeliveryDefinition, (newValue) => {
  let specificationsForDelivery: ProductSpecification[] = [];
  if (newValue) {
    specificationsForDelivery = specifications.value.filter((x) => x.definition.deliveryDefinitionId == newValue.id);
  }
  updateMetadata(specificationsForDelivery);
});

const updateMetadata = (specificationsForDelivery: ProductSpecification[]) => {
  const now = new Date();
  const date = new Date(now && now.setHours(0, 0, 0, 0));
  const metadata = specificationsForDelivery.map((specification) => {
    return {
      versionDate: date,
      versionDescription: '',
      temporalLimitationFrom: date,
      temporalLimitationTo: '',
      specification,
    };
  });
  setFieldValue('metadata', metadata);
};

const onCheckboxChecked = (evt, handleChange) => {
  handleChange(evt.target.checked);
};

onMounted(async () => {
  await DeliveryCreateServiceInstance.init();
  resetForm({ values: delivery.value });
});

onUnmounted(async () => {
  await DeliveryCreateServiceInstance.clearState();
});

const onSuccess = async (values) => {
  const payload: CreateDelivery = {
    definition: definitions.value.find((p) => p.id == values.definition?.id),
    isFirstTime: values.isFirstTime,
    file: values.file,
    metadata: createMetadata(values.metadata),
  };

  await create(payload);
};

const createMetadata = (metadata): Metadata[] => {
  return metadata.map((m) => {
    return {
      versionDate: parseFormattedDateString(m.versionDate),
      versionDescription: m.versionDescription,
      temporalLimitationFrom: parseFormattedDateString(m.temporalLimitationFrom),
      temporalLimitationTo: parseFormattedDateString(m.temporalLimitationTo),
      productSpecificationId: m.specification.id,
      productSpecificationLabel: m.specification.label,
    };
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const removeFile = (): void => {
  setFieldValue('file', null, false);
};

const create = async (delivery: CreateDelivery) => {
  try {
    closeAlert();
    const response = await data.deliveryService.create(delivery);
    router.push({ name: 'delivery.detail', params: { id: `${response.id}` }, query: { created: 'true' } });
  } catch (e: any) {
    const { detail, title } = parseException(e);
    alertError(detail, title);
  }
};

const parseException = (e: any) => {
  let title = translations.form.uploadFailed;
  let detail = '';

  if (e.status === 400) {
    const errors = e.errors;
    if (errors && Array.isArray(errors) && errors.length > 0) {
      if (errors[0].detail) {
        detail = errors.map((err) => err.detail).join(',');
      } else {
        detail = errors.join(',');
      }
    } else if (e.message) {
      detail = e.message;
    }
  } else if (e.status === 413) {
    title = translations.file.fileSize;
  }

  return { title, detail };
};

const alertError = (message: string, title: string) => {
  data.alert = {
    visible: true,
    isError: true,
    isSuccess: false,
    scrollTo: true,
    icon: 'alert-circle',
    title: title,
    content: message,
  };
};

const closeAlert = () => {
  data.alert = {
    visible: false,
    isError: false,
    isSuccess: false,
    scrollTo: false,
    icon: '',
    title: '',
    content: '',
  };
};

const cancel = () => {
  data.deliveryService.cancel();
  // Go back in history
  router.go(-1);
};
</script>
<style lang="scss">
@import '@govflanders/vl-ui-design-system-style/scss/core/_core.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-upload-progress {
  border: 1px $vl-input-border-color solid;
  border-radius: 2px;
  padding: calc(#{$vl-input-padding-sides} * 2);

  &__file {
    text-transform: uppercase;
    display: inline-flex;
    margin-top: -29px;
    margin-left: -5px;
    padding: 0 5px;
    letter-spacing: 1px;
    font-size: 12px;
    color: $vl-text-color;
    background: white;
  }

  &__label {
    text-align: center;
  }
  progress::-moz-progress-bar {
    background: $vl-yellow;
  }
  progress::-webkit-progress-value {
    background: $vl-yellow;
  }
  progress {
    color: $vl-yellow;
  }
}

.vl-upload__files__close {
  display: none;
}
</style>
