import Vue, { reactive } from 'vue';

import {
    clients,
    ProductFilter,
    ProductSpecification,
    ProductStatusType,
    IProductRunClient,
    Paging,
    SearchResultOfProductFilterAndProductSearchResult,
    Sorting,
} from '@/api';
import { IProductSpecificationStore } from '@/store/productSpecification.store';
import { stores } from '@/store';
import { IProductRunStore } from '@/store/productRun.store';

export class ProductListService {
    public state: SearchResultOfProductFilterAndProductSearchResult = reactive({
        filter: {},
        results: [],
        paging: {},
        sorting: {},
    });

    constructor(
        private productClient: IProductRunClient,
        private productStore: () => IProductRunStore,
        private specificationStore: () => IProductSpecificationStore,
    ) {}

    get specifications(): ProductSpecification[] {
        return this.specificationStore().specifications;
    }
    get statusses(): ProductStatusType[] {
        return this.productStore().statusses;
    }

    get filter(): ProductFilter {
        return this.state.filter || {};
    }

    async search(
        filter: ProductFilter = {},
        sorting: Sorting = {},
        paging: Paging = { pageNumber: 1, pageSize: 10 },
    ): Promise<void> {
        const results = await this.productClient.getAll(
            filter.specification,
            filter.versionDescription,
            filter.startExecutionFrom,
            filter.startExecutionTo,
            filter.status,
            filter.isDryRun,
            sorting.by,
            sorting.descending,
            paging.pageNumber,
            paging.pageSize,
        );

        Object.assign(this.state, results);
    }

    async init(): Promise<void> {
        await this.specificationStore().fetchSpecifications();
        await this.productStore().fetchStatusses();
    }
}

export const ProductListServiceInstance = new ProductListService(
    clients.ProductRunClient,
    stores.ProductStore,
    stores.ProductSpecificationStore,
);
