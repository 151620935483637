import { createInstance, dateReviver } from '@/api';
import {
    ProductProcessDefinitionDependenciesReportDto,
    ProductProcessDefinitionClient,
    ProductProcessDefinitionDetailDto,
    ProductProcessDefinitionCreateDto,
    ProductProcessDefinitionUpdateDto,
    ProductProcessDefinitionCascadeDeleteOptionsDto,
    ProductProcessDefinitionExportOptionsDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProductProcessDefinitionEditStore } from './product-process-definition-edit.store';
import { useSourceSpecificationStore } from '../source-specification/source-specification.store';
import { ENUM_StatusTypes } from '../../common';
import downloadBlob from '@/helpers/download.helpers';

export interface ProductProcessDefinitionDetailStoreState {
    detail?: ProductProcessDefinitionDetailDto;
    dependenciesReport?: ProductProcessDefinitionDependenciesReportDto;
    loading: boolean;
    reloading: boolean;
}

export const client = new ProductProcessDefinitionClient('', createInstance(), dateReviver);

export const useProductProcessDefinitionDetailStore = defineStore(
    'configuration-product-process-definition-detail-store',
    {
        state: (): ProductProcessDefinitionDetailStoreState => ({
            detail: null,
            loading: false,
            reloading: false,
        }),
        getters: {
            isDraft(): boolean {
                return this.detail?.statusId === ENUM_StatusTypes.find((x) => x.name === 'Draft')?.id;
            },
        },
        actions: {
            async get(id: number, reloading: boolean = false) {
                reloading ? (this.reloading = true) : (this.loading = true);
                try {
                    this.detail = await client.getDetail(id);
                    return this.detail;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.get.title'),
                        i18n.global.t('productprocessdefinition.detail.error.get.content'),
                    );
                } finally {
                    reloading ? (this.reloading = false) : (this.loading = false);
                }
            },
            async getDependenciesReport(id: number, reloading: boolean = false) {
                reloading ? (this.reloading = true) : (this.loading = true);
                try {
                    this.dependenciesReport = await client.getDependenciesReport(id);
                    return this.dependenciesReport;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.get.title'),
                        i18n.global.t('productprocessdefinition.detail.error.get.content'),
                    );
                } finally {
                    reloading ? (this.reloading = false) : (this.loading = false);
                }
            },
            async changeStatus(id: number, statusType: string): Promise<boolean> {
                this.reloading = true;
                try {
                    await client.changeStatus(id, statusType);
                    useAlertStore().setSuccess(
                        i18n.global.t('productprocessdefinition.detail.success.update.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    await this.get(id, true);
                    return true;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.update.title'),
                        i18n.global.t('productprocessdefinition.detail.error.update.content'),
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    return false;
                } finally {
                    this.reloading = false;
                }
            },
            async create(body: ProductProcessDefinitionCreateDto) {
                this.loading = true;
                let response = null;
                try {
                    response = await client.create(body);
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.create.error.title'),
                        i18n.global.t('productprocessdefinition.create.error.content'),
                    );
                } finally {
                    this.loading = false;
                }
                return response;
            },
            async update(id: number, dto: ProductProcessDefinitionUpdateDto) {
                this.reloading = true;
                try {
                    await client.update(id, dto);
                    useAlertStore().setSuccess(
                        i18n.global.t('productprocessdefinition.detail.success.update.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    await this.get(id, true);
                    return true;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.update.title'),
                        i18n.global.t('productprocessdefinition.detail.error.update.content'),
                    );
                } finally {
                    useProductProcessDefinitionEditStore().closeEdit();
                    this.reloading = false;
                }
            },
            async delete(id: number) {
                this.reloading = true;
                try {
                    await client.delete(id);
                    useAlertStore().setSuccess(
                        i18n.global.t('productprocessdefinition.detail.success.delete.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    await this.get(id, true);
                    return true;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.delete.title'),
                        i18n.global.t('productprocessdefinition.detail.error.delete.content'),
                    );
                } finally {
                    this.reloading = false;
                }
            },
            async duplicate(
                id: number,
                { duplicateProductMetadata = false }: { duplicateProductMetadata: boolean },
            ): Promise<number> {
                let response = null;
                try {
                    response = await client.duplicate(id, {
                        duplicateEntityModels: true,
                        duplicateProductMetadata,
                    });
                    useAlertStore().setSuccess(
                        i18n.global.t('productprocessdefinition.detail.success.duplicate.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.duplicate.title'),
                        i18n.global.t('productprocessdefinition.detail.error.duplicate.content'),
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                }
                return response?.id;
            },
            async export(id: number, exportOptions: ProductProcessDefinitionExportOptionsDto): Promise<void> {
                try {
                    const file = await client.export(id, exportOptions);

                    return new Promise((resolve) => {
                        downloadBlob(file.data, file.fileName, () => {
                            resolve();
                        });
                    });
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.export.title'),
                        i18n.global.t('productprocessdefinition.detail.error.export.content'),
                    );
                }
            },
            async import(file: File): Promise<number> {
                let response = null;
                try {
                    response = await client.import({ data: file, fileName: file.name });
                    useAlertStore().setSuccess(
                        i18n.global.t('productprocessdefinition.detail.success.import.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                } catch (error: any) {
                    const detail = error?.errors[0]?.detail;
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.import.title'),
                        detail || i18n.global.t('productprocessdefinition.detail.error.import.content'),
                    );
                }
                return response?.id;
            },
            async cascadeDelete(
                id: number,
                options: ProductProcessDefinitionCascadeDeleteOptionsDto = { deleteEntityModels: true },
            ) {
                this.reloading = true;
                try {
                    await client.cascadeDelete(id, options);
                    useAlertStore().setSuccess(
                        i18n.global.t('productprocessdefinition.detail.success.delete.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    return true;
                } catch (error: any) {
                    const detail = error?.errors[0]?.detail;
                    useAlertStore().setError(
                        i18n.global.t('productprocessdefinition.detail.error.delete.title'),
                        detail || i18n.global.t('productprocessdefinition.detail.error.delete.content'),
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                } finally {
                    useProductProcessDefinitionEditStore().closeEdit();
                    this.reloading = false;
                }
            },
            async validateCascadeDelete(id: number): Promise<boolean> {
                try {
                    const isValid = await client.cascadeDeleteValidate(id);
                    return isValid;
                } catch (error) {
                    console.error('validateCascadeDelete', error);
                    return false;
                }
            },
            clear(id: number) {
                this.detail = null;
                this.dependenciesReport = null;
                useSourceSpecificationStore().clear(id);
            },
        },
    },
);
