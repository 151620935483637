<template>
  <vl-layout>
    <PbsGoBackLink />
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="6" width-xs="12">
          <vl-title tag-name="h1">
            {{ $t('productprocessdefinition.detail.title') }} <PbsStatusIcon v-if="detail" :id="detail?.statusId" />
          </vl-title>
        </vl-column>
        <vl-column width="6" width-xs="12">
          <vl-action-group
            v-if="detail && !editStore.isEdit(ProductProcessDefinitionEditType.ProductProcessDefinition, detail.id)"
            mod-align-right
            mod-space
            between
          >
            <vl-button
              v-if="editStore.canEdit"
              icon="pencil"
              mod-icon-before
              mod-secondary
              :mod-disabled="editStore.editing"
              @click="editStore.openEdit(ProductProcessDefinitionEditType.ProductProcessDefinition, detail.id)"
            >
              {{ $t('general.edit') }}
            </vl-button>
            <vl-button
              v-if="editStore.canEdit && !hasChildren"
              icon="trash"
              mod-icon-before
              mod-error
              @click="editStore.openDelete(ProductProcessDefinitionEditType.ProductProcessDefinition, id)"
              >{{ $t('general.delete') }}</vl-button
            >
            <PbsActionButton :label="$t('general.moreActions')" :items="actionButtonItems"> </PbsActionButton>
          </vl-action-group>
          <vl-action-group v-else mod-align-right mod-space between>
            <vl-button icon="save" :mod-loading="reloading" mod-icon-before @click="save">{{
              $t('general.save')
            }}</vl-button>
            <vl-button
              icon="cross"
              :mod-loading="loading"
              mod-icon-before
              mod-secondary
              mod-error
              @click="editStore.closeEdit"
            >
              {{ $t('general.cancel') }}
            </vl-button>
          </vl-action-group>
        </vl-column>
      </vl-grid>
      <vl-grid
        v-if="detail"
        v-vl-spacer:bottom.medium
        :class="{ edit: editStore.isEdit(ProductProcessDefinitionEditType.ProductProcessDefinition, detail.id) }"
      >
        <vl-column>
          <ProductProcessDefinitionEdit
            v-if="editStore.isEdit(ProductProcessDefinitionEditType.ProductProcessDefinition, detail.id)"
            ref="editForm"
          />
          <ProductProcessDefinitionRead v-else />
        </vl-column>
      </vl-grid>
      <template v-if="detail && !loading">
        <SourceSpecification />
        <div class="vl-u-spacer--large vl-u-visible--s"></div>
        <ProductSpecification />
        <div class="vl-u-spacer--large vl-u-visible--s"></div>
        <ProductTransformation />
      </template>
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('productprocessdefinition.detail.loading')" />
        </div>
      </vl-region>
    </template>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('productprocessdefinition.detail.delete.title')"
      :open="editStore.isDelete(ProductProcessDefinitionEditType.ProductProcessDefinition, deleteKey)"
    >
      <vl-action-group>
        <vl-button v-if="!hasChildren" icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </vl-layout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router';
import { useProductProcessDefinitionDetailStore } from '../../store/product-process-definition/product-process-definition-detail.store';
import { ProductProcessDefinitionDetailDto } from '@/api/portal-api/clients';
import ProductProcessDefinitionRead from './ProductProcessDefinitionRead.vue';
import {
  ProductProcessDefinitionEditType,
  useProductProcessDefinitionEditStore,
} from '../../store/product-process-definition/product-process-definition-edit.store';
import { useAlertStore } from '@/modules/core/store/alert.store';
import ProductProcessDefinitionEdit from './ProductProcessDefinitionEdit.vue';
import SourceSpecification from './subcomponents/source-specification/SourceSpecification.vue';
import { useSourceSpecificationStore } from '../../store/source-specification/source-specification.store';
import ProductSpecification from './subcomponents/product-specification/ProductSpecification.vue';
import { useProductSpecificationStore } from '../../store/product-specification/product-specification.store';
import ProductTransformation from './subcomponents/product-transformation/ProductTransformation.vue';
import DefinitionDryRunForm from './subcomponents/definition-dryrun-form/DefinitionDryRunForm.vue';
import DefinitionExportForm from './subcomponents/definition-export-form/DefinitionExportForm.vue';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';
import PbsStatusIcon from '@/components/PbsStatusIcon.vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import PbsActionButton, { PbsActionButtonItem } from '@/components/PbsActionButton.vue';

const store = useProductProcessDefinitionDetailStore();
const editStore = useProductProcessDefinitionEditStore();
const modalStore = useModalStore();
const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const editForm = ref();

const detail = computed((): ProductProcessDefinitionDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const actionButtonItems = computed((): PbsActionButtonItem[] => {
  return [
    {
      label: t('productprocessdefinition.actions.dryrun.label'),
      click: showDryRunModal,
      disabled: !editStore.canEdit,
    },
    {
      label: t('general.export'),
      click: showExportModal,
    },
  ];
});

const hasChildren = computed((): boolean => {
  return (
    useProductSpecificationStore().byDefinition(id.value).length > 0 ||
    useSourceSpecificationStore().byDefinition(id.value)?.length > 0
  );
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

onMounted(() => {
  useAlertStore().clearAlert();
  store.get(id.value);
});

const save = () => {
  editForm.value.onSubmit();
};

const remove = async (id: number) => {
  const isSuccess = await store.delete(id);
  editStore.closeDelete();
  if (isSuccess) {
    router.push({ name: 'configuration', hash: '#productprocessdefinition' });
  }
};

const showDryRunModal = () => {
  const modalContent = {
    title: t('productprocessdefinition.actions.dryrun.description'),
    component: DefinitionDryRunForm,
    props: {
      id: id.value,
    },
  };

  modalStore.showModal(modalContent);
};

const showExportModal = () => {
  const modalContent = {
    title: t('productprocessdefinition.actions.export.description'),
    component: DefinitionExportForm,
    props: {
      id: id.value,
    },
  };

  modalStore.showModal(modalContent);
};

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
    editStore.closeEdit();
  }
  next();
});
</script>
