import { createInstance, dateReviver } from '@/api';
import {
    EntityModelDependenciesReportDto,
    EntityModelClient,
    EntityModelDetailDto,
    CreateEntityModelDto,
    UpdateEntityModelDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useEntityAttributeStore } from '../entity-attribute/entity-attribute.store';
import { useEntityModelEditStore } from './entity-model-edit.store';
import { ENUM_StatusTypes } from '../../common';
import downloadBlob from '@/helpers/download.helpers';

export interface EntityModelDetailStoreState {
    detail?: EntityModelDetailDto;
    dependenciesReport?: EntityModelDependenciesReportDto;
    loading: boolean;
    reloading: boolean;
}

export const client = new EntityModelClient('', createInstance(), dateReviver);

export const useEntityModelDetailStore = defineStore('configuration-entity-model-detail-store', {
    state: (): EntityModelDetailStoreState => ({
        detail: null,
        loading: false,
        reloading: false,
    }),
    getters: {
        isDraft(): boolean {
            return this.detail?.statusId === ENUM_StatusTypes.find((x) => x.name === 'Draft')?.id;
        },
    },
    actions: {
        async get(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                this.detail = await client.getDetail(id);
                return this.detail;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.get.title'),
                    i18n.global.t('entitymodel.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async getDependenciesReport(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                this.dependenciesReport = await client.getDependenciesReport(id);
                return this.dependenciesReport;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.get.title'),
                    i18n.global.t('entitymodel.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(dto: CreateEntityModelDto): Promise<number> {
            this.loading = true;
            let response = null;
            try {
                response = await client.create(dto);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.create.error.title'),
                    i18n.global.t('entitymodel.create.error.content'),
                );
            } finally {
                useEntityModelEditStore().closeEdit();
                this.loading = false;
            }
            return response?.id;
        },
        async update(id: number, dto: UpdateEntityModelDto) {
            try {
                this.reloading = true;
                await client.update(id, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('entitymodel.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.get(id, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.update.title'),
                    i18n.global.t('entitymodel.detail.error.update.content'),
                );
            } finally {
                useEntityModelEditStore().closeEdit();
                this.reloading = false;
            }
        },
        async delete(id: number) {
            this.reloading = true;
            try {
                await client.delete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('entitymodel.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                return true;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.delete.title'),
                    i18n.global.t('entitymodel.detail.error.delete.content'),
                );
            } finally {
                useEntityModelEditStore().closeEdit();
                this.reloading = false;
            }
        },
        async cascadeDelete(id: number) {
            this.reloading = true;
            try {
                await client.cascadeDelete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('entitymodel.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                return true;
            } catch (error: any) {
                const detail = error?.errors[0]?.detail;
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.delete.title'),
                    detail || i18n.global.t('entitymodel.detail.error.delete.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                useEntityModelEditStore().closeEdit();
                this.reloading = false;
            }
        },
        async validateCascadeDelete(id: number): Promise<boolean> {
            try {
                const isValid = await client.cascadeDeleteValidate(id);
                return isValid;
            } catch (error) {
                console.error('validateCascadeDelete', error);
                return false;
            }
        },
        async changeStatus(id: number, statusType: string): Promise<boolean> {
            this.reloading = true;
            try {
                await client.changeStatus(id, statusType);
                useAlertStore().setSuccess(
                    i18n.global.t('entitymodel.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                await this.get(id, true);
                return true;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.update.title'),
                    i18n.global.t('entitymodel.detail.error.update.content'),
                );
                return false;
            } finally {
                this.reloading = false;
            }
        },
        async duplicate(id: number): Promise<number> {
            let response = null;
            try {
                response = await client.duplicate(id);
                useAlertStore().setSuccess(
                    i18n.global.t('entitymodel.detail.success.duplicate.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.duplicate.title'),
                    i18n.global.t('entitymodel.detail.error.duplicate.content'),
                );
            }
            return response?.id;
        },
        async export(id: number): Promise<void> {
            try {
                const file = await client.export(id);

                return new Promise((resolve) => {
                    downloadBlob(file.data, file.fileName, () => {
                        resolve();
                    });
                });
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.export.title'),
                    i18n.global.t('entitymodel.detail.error.export.content'),
                );
            }
        },
        async import(file: File): Promise<number> {
            let response = null;
            try {
                response = await client.import({ data: file, fileName: file.name });
                useAlertStore().setSuccess(
                    i18n.global.t('entitymodel.detail.success.import.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch (error: any) {
                const detail = error?.errors[0]?.detail;
                useAlertStore().setError(
                    i18n.global.t('entitymodel.detail.error.import.title'),
                    detail || i18n.global.t('entitymodel.detail.error.import.content'),
                );
            }
            return response?.id;
        },
        clear(id: number) {
            this.detail = null;
            this.dependenciesReport = null;
            useEntityAttributeStore().clear(id);
        },
    },
});
