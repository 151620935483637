<template>
  <form class="vl-form" @submit.prevent="onSubmit">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <Field v-slot="{ handleChange, value, errors }" name="file">
          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
          <vl-upload
            id="data-upload"
            name="data-upload"
            :upload-label="$t('productprocessdefinition.modals.import.uploadLabel')"
            allowed-file-types=".json"
            :max-filesize="5"
            :upload-drag-text="$t('vl-upload.uploadDragText')"
            :upload-response-error="$t('vl-upload.uploadResponseError')"
            :upload-invalid-file-type="$t('vl-upload.uploadInvalidFileType')"
            :upload-max-files-exceeded="$t('vl-upload.uploadMaxFilesExceeded')"
            :upload-files-hidden-close-label="$t('vl-upload.uploadFilesHiddenCloseLabel')"
            :upload-max-file-size="$t('vl-upload.uploadMaxFileSize')"
            url="/api/v1/configuration/productprocessdefinition/import"
            :mod-error="!!errors.length"
            :max-files="1"
            :options="options"
            :value="value"
            @upload-file-added="handleChange"
            @upload-removed-file="removeFile"
          />
        </Field>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{
            $t('productprocessdefinition.actions.import.label')
          }}</vl-button>
          <vl-button
            v-focus
            :mod-disabled="data.isSaving"
            mod-icon-before
            mod-secondary
            tag-name="a"
            @click.prevent="cancel"
          >
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import 'vue-i18n';
import * as yup from 'yup';
import { Field, useForm } from 'vee-validate';
import { reactive } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { DropzoneFile, DropzoneOptions } from 'dropzone';
import type Dropzone from 'dropzone';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const store = useProductProcessDefinitionDetailStore();

const { t } = useI18n();

const router = useRouter();

const data = reactive({
  isSaving: false,
});

const validationSchema = yup.object({
  file: yup
    .mixed()
    .required(t('productprocessdefinition.modals.import.validations.fileRequired'))
    .test('is-valid-type', t('productprocessdefinition.modals.import.validations.invalidFileType'), (value) => {
      return value && (value as File).type === 'application/json';
    })
    .test(
      'is-valid-size',
      t('productprocessdefinition.modals.import.validations.invalidFileSize'),
      (value) => value && (value as File).size <= 5 * 1000 * 1000,
    ),
});

const { handleSubmit, setFieldValue } = useForm({
  validationSchema,
});

const options: DropzoneOptions = {
  init: function (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const dropzone: Dropzone = this;
    // Hack to fix 'reuploading' of a file. Uploading a new file replaces the other one.
    dropzone.on('maxfilesexceeded', function (file: DropzoneFile) {
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    });
  },
};

const removeFile = (): void => {
  setFieldValue('file', null, false);
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onSuccess = async (values) => {
  const id = await store.import(values.file);

  closeModal();

  if (id) {
    router.push({
      name: 'productprocessdefinition.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>
