import { createInstance, dateReviver } from '@/api';
import {
    ProductRunClient
} from '@/api';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';

export interface ProductRunStoreState {
    loading: boolean;
    reloading: boolean;
}

export const client = new ProductRunClient('', createInstance(), dateReviver);

export const useProductRunStore = defineStore(
    'configuration-product-run-store', {
    state: (): ProductRunStoreState => ({
        loading: false,
        reloading: false
    }),
    actions: {
        async startdryrun(definitionId: number) {
            this.reloading = true;

            try {
                await client.createDryRun(definitionId);

                useAlertStore().setSuccess(
                    i18n.global.t('productprocessdefinition.detail.success.dryrun.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productprocessdefinition.detail.error.dryrun.title'),
                    i18n.global.t('productprocessdefinition.detail.error.dryrun.content'),
                );
                return null;
            } finally {
                this.reloading = false;
            }
        }
    }
});
